import { Chips } from "@hyperlocal/vital";
import styled from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const Container = styled.article(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacings.stack.x2s,

  position: "absolute",
  inset: 0,
  gridColumn: "2",

  [EBreakPoints.mobile]: {
    width: "100%",
    minHeight: "calc(100vh - 56px)",
    gap: "unset",
    top: 56,
    zIndex: 9999
  },
}));

export const ListContainer = styled.div((props) => ({
  height: "100%",
  width: "100%",

  padding: `${props.theme.spacings.stack.x3s} ${props.theme.spacings.stack.x2s}`,

  display: "flex",
  flexDirection: "column",

  backgroundColor: props.theme.palette.neutral.white,

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.lighter}`,

  overflow: "hidden",

  "& input": {
    display: "none",
    boxSizing: "content-box",

    "&[data-visible='true']": {
      display: "block",
    },
    "&[data-visible='false']": {
      display: "none",
    },
  },

  [EBreakPoints.mobile]: {
    padding: `0 ${props.theme.spacings.stack.x3s}`,
    width: "100vw",
    minWidth: "unset",
    border: 0,
  },
}));

export const FiltersRow = styled.div((props) => ({
  display: "none",

  [EBreakPoints.mobile]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "div:last-of-type": {
      justifyContent: "flex-end",
      "& > button": {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        "& svg": {
          maxWidth: "100%",
          fill: props.theme.palette.neutral.dark,
          minWidth: 24,
        },
      },
    },

    "& div": {
      width: "100%",

      display: "flex",
      alignItems: "center",
      gap: props.theme.spacings.inline.xs,
    },
  },
}));

export const List = styled.ul((props) => ({
  width: "100%",
  height: "100%",

  overflowY: "auto",
  li: {
    "& + li": {
      marginTop: props.theme.spacings.stack.x3s,
    },
  },
}));

export const ListHeadings = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  marginBottom: props.theme.spacings.stack.x3s,
  marginTop: props.theme.spacings.stack.x3s,

  h5: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.sm,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
  },

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.sm,
    color: props.theme.palette.neutral.dark,
  },

  [EBreakPoints.mobile]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
    h5: {
      fontSize: props.theme.fontSizes.xs,
    },

    p: {
      fontWeight: props.theme.fontWeights.medium,
      fontSize: props.theme.fontSizes.x3s,
    },
  },
}));

export const ListItem = styled.li((props) => ({
  listStyleType: "none",
  width: "100%",
  height: 73,

  "& button": {
    cursor: "pointer",
    backgroundColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",

    padding: "12px 24px",
    gap: 16,

    width: "100%",
    height: "100%",

    border: `1px solid ${props.theme.palette.neutral.light}`,
    borderRadius: props.theme.borderRadius.sm,
    "&:hover": {
      border: `1px solid ${props.theme.palette.primary.main}`,
      transition: "border-color 300ms linear 0s",
    },

    "& svg": {
      display: "block",
      color: props.theme.palette.neutral.darkest,
      "& path": {
        fill: props.theme.palette.neutral.darkest,
      },
    },
  },

  [EBreakPoints.mobile]: {
    width: "100%",
    height: 67,
  },
}));

export const ListItemInfo = styled.div((props) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  "& div": {
    width: "100%",
    height: "100%",
  },
  "& div:first-of-type": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& p:nth-of-type(1)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.darkest,
      [EBreakPoints.mobile]: {
        fontSize: 10,
      },
    },
    "& p:nth-of-type(2)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      [EBreakPoints.mobile]: {
        fontSize: props.theme.fontSizes.x2s,
      },
    },
  },

  "& div:nth-of-type(2)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& p:nth-of-type(1)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      [EBreakPoints.mobile]: {
        fontSize: 10,
      },
    },
    "& p:nth-of-type(2)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.sm,
      color: props.theme.palette.secondary.darker,
      [EBreakPoints.mobile]: {
        fontSize: props.theme.fontSizes.xs,
      },
    },
  },

  "div:has(svg)": {
    display: "flex",
    alignItems: "center",
    width: 24,
    height: 24,
    marginLeft: 16,
  },

  [EBreakPoints.mobile]: {},
}));

export const SvgContainer = styled("span")((props) => ({
  display: "flex",
  alignItems: "center",
  width: 24,
  height: 24,
  marginLeft: props.theme.spacings.inset.md,
}));

export const Button = styled(Chips)((props) => ({
  width: 106,

  marginTop: `${props.theme.spacings.stack.nano}`,
  marginBottom: `${props.theme.spacings.stack.nano}`,

  [EBreakPoints.mobile]: {
    width: "82px",
    svg: {
      width: "24px",
      height: "24px",
    },
  },
}));

export const SaleNotFound = styled.div((props) => ({
  textAlign: "center",
  margin: props.theme.spacings.inline.x2s,

  "& p": {
    fontWeight: props.theme.fontWeights.medium,
    fontSize: props.theme.fontSizes.x3s,
    color: props.theme.palette.neutral.main,
  },
}));
